import { addDays, endOfMonth, endOfWeek, format, formatDate, getDay, getDaysInMonth, getMonth, getYear, isAfter, parse, startOfMonth, startOfWeek, subMonths, subWeeks, subYears } from 'date-fns';
import { enUS } from 'date-fns/locale';

export function getLastWeekDate(formatStr: string, locale = enUS) {
  const lastWeekStartDate = startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1, locale });
  const lastWeekEndDate = endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1, locale });

  if (formatStr) {
    return `${format(lastWeekStartDate, formatStr, { locale })} - ${format(lastWeekEndDate, formatStr, { locale })}`;
  } else {
    return { start: lastWeekStartDate, end: lastWeekEndDate };
  }
}

export function getCurrentWeekDate(formatStr: string | null, locale = enUS) {
  const currentWeekStartDate = startOfWeek(new Date(), { weekStartsOn: 1 });
  const currentWeekEndDate = endOfWeek(new Date(), { weekStartsOn: 1 });

  if (formatStr) {
    return `${formatDate(currentWeekStartDate, formatStr, { locale })} - ${formatDate(currentWeekEndDate, formatStr, { locale })}`;
  } else {
    return { start: currentWeekStartDate, end: currentWeekEndDate };
  }
}

export function getLastMonthDate(formatStr: string | null, locale = enUS) {
  const lastMonthStartDate = startOfMonth(subMonths(new Date(), 1));
  const lastMonthEndDate = endOfMonth(subMonths(new Date(), 1));

  if (formatStr) {
    return `${formatDate(lastMonthStartDate, formatStr, { locale })} - ${formatDate(lastMonthEndDate, formatStr, { locale })}`;
  } else {
    return { start: lastMonthStartDate, end: lastMonthEndDate };
  }
}

export function getCurrentMonthDate(formatStr: string | null, locale = enUS) {
  const currentMonthStartDate = startOfMonth(new Date());
  const currentMonthEndDate = endOfMonth(new Date());

  if (formatStr) {
    return `${formatDate(currentMonthStartDate, formatStr, { locale })} - ${formatDate(currentMonthEndDate, formatStr, { locale })}`;
  } else {
    return { start: currentMonthStartDate, end: currentMonthEndDate };
  }
}


export function getWeeksAndDates(year: number, month: number) {
  const startDate = startOfMonth(new Date(year, month, 1));

  const daysInMonth = getDaysInMonth(startDate);

  const weeksAndDates: string[][] = [];

  let currentWeek: string[] = [];
  let currentDate = startDate;

  for (let day = 1; day <= daysInMonth; day++) {
    currentWeek.push(format(currentDate, 'MMM dd'));
    currentDate = addDays(currentDate, 1);

    // If it's a Sunday or the last day of the month, start a new week
    if (getDay(currentDate) === 1 || day === daysInMonth) {
      weeksAndDates.push(currentWeek);
      currentWeek = [];
    }
  }

  return weeksAndDates;
}

export function getMonthIndex(dateString: string): number {
  const date = parse(dateString, 'yyyy, MMM dd', new Date());
  return getMonth(date); // Returns the month index from 0 to 11
}


export function getCurrentYear(): number {
  return getYear(new Date());
}

export function getPreviousYear(): number {
  return getYear(subYears(new Date(), 1));
}

export function getYearOfPreviousMonth(): number {
  const currentDate = new Date();
  const previousMonthDate = subMonths(currentDate, 1);

  // Return the year of the previous month
  if (getYear(previousMonthDate) <= getYear(currentDate)) {
    return getYear(previousMonthDate);
  } else {
    // If the previous month is in the previous year
    return getYear(currentDate) - 1;
  }
}

export function convertToUTC(time: string): string {
  if (!time) {
    return '';
  }
  const parsedTime = parse(time, 'h:mm a', new Date());
  const date = new Date();
  date.setHours(parsedTime.getHours(), parsedTime.getMinutes(), 0, 0);

  const localOffset = date.getTimezoneOffset() * 60000;

  const utcDate = new Date(date.getTime() + localOffset);

  const utcFormatted = format(utcDate, "HH:mm:ss.SSS'Z'");

  return utcFormatted;
}

export function convertToLocal(utcTime: string): string {
  if (!utcTime) {
    return '';
  }
  let parsedUTCTime;
  if (utcTime.includes('000Z')) {
    parsedUTCTime = parse(utcTime, "HH:mm:ss.SSS'Z'", new Date())
  } else {
    parsedUTCTime = parse(utcTime, 'HH:mm:ss', new Date())
  }

  const localOffset = new Date().getTimezoneOffset() * 60000;
  const localTime = new Date(parsedUTCTime.getTime() - localOffset);
  const localFormatted = format(localTime, 'hh:mm a');

  return localFormatted;
}

export function compareTime(startTime: string, endTime: string): boolean {
  const formatStr = 'h:mm a';

  const start = parse(startTime, formatStr, new Date());
  const end = parse(endTime, formatStr, new Date());

  const result = isAfter(start, end);
  return result;
}
